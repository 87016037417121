import logo from './brain_logo.jpg';
import './App.css';
import {useState, useRef, useEffect} from 'react';

function App() {
  const [email, setEmail] = useState("");

  // "https://codepen.io/alvarotrigo/pen/gOmgRzL"
  useEffect(() => {
    const scrollContainer = document.querySelector("main");
    scrollContainer.addEventListener("wheel", (evt) => {
        evt.preventDefault();
        scrollContainer.scrollLeft += 0.5 * evt.deltaY;
    })
  }, [])

    

  const Intro = () => {
    return (
      <div className="flex items-center justify-center">
        <div className="text-white font-anonymous-pro text-center text-8xl font-normal opacity-60 
        bg-black h-screen w-screen flex items-center justify-center">
            PERSPECTIVE
        </div>
      </div>
    )
  }

  const About = () => {
    return (
      <div className="flex items-center justify-center">
        <div className="text-white font-anonymous-pro text-center text-8xl font-normal bg-gradient-to-r from-black to-blue-800
         h-screen bg-opacity-35 w-screen opacity-60 flex flex-col justify-center  ">
            <div> 
              venture capital fund
            </div>
            <div> 
              empowering crypto innovation 
            </div>
        </div>
      </div>
    )
  }

  const Contact = () => {
    return (
      <div className="flex items-center justify-center">
        <div className="text-white font-anonymous-pro text-center text-8xl font-normal bg-gradient-to-r from-blue-800 to-black
         h-screen w-screen opacity-60 flex flex-col justify-center  ">
            <div> 
              subscribe
            </div>
            <div> 
              <input type="text" className="bg-white text-sm text-black m-4 p-4 h-[50%]" placeholder="Email"/>
            </div>
        </div>
      </div>
    )
  }


  return (
    <main className='w-screen h-screen overflow-x-hidden bg-black p-5 relative'>
      <div className="absolute inset-0 z-10 flex items-center">
        <Intro />
        <About />
        <Contact />
      </div>

      <div className="fixed flex items-center justify-center h-screen w-screen z-1">
          <img src={logo} alt="Spinning Image" className="w-52 h-52 spin" />
      </div>
    </main>
  );
}

export default App;
